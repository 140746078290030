<template>
  <div :class="mobile ? '' : 'd-flex align-baseline'">
    <div :class="mobile ? 'col d-flex justify-space-between py-1 px-0' : ''">
      <v-btn-toggle
        :rounded="!mobile"
        :dense="mobile"
        :value="selectedProfile"
        @change="changeProfile($event)"
        color="primary"
        id="profile-btn-toggle"
      >
        <v-btn id="all-btn">{{ $t("all") }}</v-btn>
        <v-btn id="todo-btn" v-if="userHasFilterProfile">{{
          $t("todo")
        }}</v-btn>
        <v-btn id="primary-btn" v-if="userHasFilterProfile">{{
          $t("primary")
        }}</v-btn>
      </v-btn-toggle>
      <v-btn
        class="ml-2"
        outlined
        icon
        @click="showFilters = !showFilters"
        id="showFilters"
      >
        <v-icon v-if="mobile">{{
          showFilters ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
        <v-icon v-else>{{
          showFilters ? "mdi-chevron-left" : "mdi-chevron-right"
        }}</v-icon>
      </v-btn>
    </div>
    <div v-show="showFilters" :class="{ 'col pl-2 pb-0 pr-0': !mobile }">
      <v-divider v-if="mobile"></v-divider>
      <v-btn-toggle
        :rounded="!mobile"
        :dense="mobile"
        multiple
        :value="categories"
        @change="changeCategories($event)"
        color="info"
        :class="mobile ? 'mb-2' : 'mb-2'"
        id="alert-type-btn-toggle"
      >
        <v-btn id="query-btn">{{ $t("query") }}</v-btn>
        <v-btn id="checking-btn">{{ $t("checking") }}</v-btn>
        <v-btn id="rule-btn">{{ $t("rules") }}</v-btn>
      </v-btn-toggle>
      <div id="subfilter">
        <v-autocomplete
          :dense="mobile"
          :label="$t('subfilters')"
          v-if="subFilters.length > 0"
          outlined
          :value="selectedFilters"
          multiple
          :items="subFilters"
          @change="changeFilters($event)"
          :class="mobile ? 'my-2' : 'mb-2 col'"
          clearable
          :allow-overflow="true"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <v-chip :x-small="mobile" close @click:close="remove(item)">
              {{ item }}
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-chip outlined>{{ item.split(":")[1] }}</v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div id="siteFilter">
        <v-autocomplete
          :dense="mobile"
          :label="$t('siteFilter')"
          v-if="siteFilters.length > 1"
          outlined
          :value="selectedSiteFilters"
          multiple
          :items="siteFilters"
          @change="changeSiteFilters($event)"
          :class="mobile ? '' : 'mb-2 col'"
          clearable
          hide-details
        >
          <template v-slot:selection="{ item }">
            <v-chip :small="mobile" close @click:close="removeSite(item)">
              {{ item }}
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-chip outlined>{{ item.split(":")[1] }}</v-chip>
          </template>
        </v-autocomplete>
      </div>
      <v-divider v-if="mobile"></v-divider>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alerts from "../../mixin/alerts";
export default {
  data() {
    return {
      allCategories: ["query", "checking", "dcf"],
      categories: [],
      selectedFilters: [],
      selectedProfile: undefined,
      selectedSiteFilters: [],
      showFilters: false
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    siteFilters() {
      return [...this.alertFilters.siteFilters];
    },
    subFilters() {
      return [
        ...this.formatSubfilter(
          this.alertFilters.queryStatusFilters,
          "Query status",
          "query"
        ),
        ...this.formatSubfilter(
          this.alertFilters.queryCreatorFilters,
          "Query creator",
          "query"
        ),
        ...this.formatSubfilter(
          this.alertFilters.queryCheckingFilters,
          "Query not checked by",
          "query"
        ),
        ...this.formatSubfilter(
          this.alertFilters.checkingByFilters,
          "Not checked by",
          "checking"
        ),
        ...this.formatSubfilter(
          this.alertFilters.ruleMessageFilters,
          "DCF types",
          "dcf"
        )
      ].filter(
        f =>
          this.categories.some(c =>
            typeof f == "string" ? f.startsWith(this.allCategories[c]) : true
          ) && !this.allCategories.includes(f)
      );
    },
    userHasFilterProfile() {
      return Object.keys(this.profiles).includes(this.currentUser.role);
    },
    userFilterProfile() {
      return this.userHasFilterProfile
        ? this.profiles[this.currentUser.role]
        : undefined;
    }
  },
  methods: {
    formatSubfilter(filters, header, category) {
      return this.categories.includes(this.allCategories.indexOf(category))
        ? [{ header: header }, ...filters, { divider: true }]
        : [];
    },
    changeCategories(categories) {
      this.categories = categories;
      if (this.categories.includes(1) && this.isInRole("cra", "datamanager")) {
        const filter = this.isInRole("cra")
          ? "checking:notCheckedByCRA"
          : "checking:notCheckedByDM";
        this.addFilter(filter);
      }
      this.selectedFilters =
        categories.length > 0
          ? this.selectedFilters.filter(f =>
              this.categories
                .map(c => this.allCategories[c])
                .includes(f.split(":")[0])
            )
          : [];
      this.send();
    },
    changeFilters(filters) {
      this.selectedFilters = filters;
      this.send();
    },
    changeProfile(profile) {
      this.selectedProfile = profile;
      if (profile == 0) this.selectedFilters = [];
      if (profile == 1)
        this.selectedFilters = this.userFilterProfile[0].concat(
          this.userFilterProfile[1]
        );
      if (profile == 2) this.selectedFilters = this.userFilterProfile[0];
      this.categories = this.selectedFilters.reduce(
        (a, f) =>
          this.allCategories.indexOf(f) != -1
            ? a.concat(this.allCategories.indexOf(f))
            : a,
        []
      );
      this.send();
    },
    changeSiteFilters(filters) {
      this.selectedSiteFilters = filters;
      this.send();
    },
    send() {
      const filters = this.categories
        .map(c => this.allCategories[c])
        .concat(...this.selectedFilters);
      filters.push(...this.selectedSiteFilters);
      this.$emit("change", filters);
    },
    remove(item) {
      const index = this.selectedFilters.indexOf(item);
      if (index >= 0) this.selectedFilters.splice(index, 1);
      this.send();
    },
    removeSite(item) {
      const index = this.selectedSiteFilters.indexOf(item);
      if (index >= 0) this.selectedSiteFilters.splice(index, 1);
      this.send();
    },
    setQueryFilters(filters) {
      Object.keys(filters).forEach(f => {
        const cat = f
          .toLowerCase()
          .replace(/^@/, "")
          .replace(/_status$/, "");
        const catIx = this.allCategories.indexOf(cat);
        if (catIx > -1 && !this.categories.includes(catIx)) {
          this.categories.push(catIx);
          this.changeFilters([`${cat}:${filters[f]}`]);
        } else if (cat == "site")
          this.changeSiteFilters([`site:${filters[f]}`]);
      });
    },
    addFilter(filter) {
      if (!this.selectedFilters.includes(filter))
        this.selectedFilters.push(filter);
    }
  },
  mixins: [alerts],
  mounted() {
    if (Object.keys(this.$route.query).length > 0)
      this.setQueryFilters(this.$route.query);
    else {
      this.selectedProfile = this.userHasFilterProfile ? 2 : 0;
      this.changeProfile(this.selectedProfile);
    }
  },
  i18n: {
    messages: {
      en: {
        subfilters: "Subfilters",
        all: "All",
        primary: "Primary",
        todo: "To Do",
        query: "Queries",
        checking: "Checkings",
        rules: "DCF",
        siteFilter: "Filter Sites"
      },
      fr: {
        subfilters: "Sous Filtres",
        all: "Tout",
        primary: "Prioritaire",
        todo: "A traiter",
        query: "Queries",
        checking: "Contrôles",
        rules: "DCF",
        siteFilters: "Filtrer les centres"
      }
    }
  }
};
</script>

<style>
.v-data-table__mobile-row {
  min-height: 38px !important;
}
</style>
