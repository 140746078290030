<template>
  <v-row>
    <v-col cols="12" :class="{ 'py-1': mobile }">
      <monitoring-filters
        :filters="options"
        @change="filter = $event"
      ></monitoring-filters>
    </v-col>
    <v-col cols="12" :class="{ 'pt-0': mobile }">
      <alert-list @input="options = $event" :filter="filter"></alert-list>
    </v-col>
  </v-row>
</template>

<script>
import AlertList from "../components/processes/AlertList";
import MonitoringFilters from "../components/processes/MonitoringFilters.vue";
import alerts from "../mixin/alerts";

export default {
  components: {
    AlertList,
    MonitoringFilters
  },
  data: function() {
    return {
      filter: [],
      options: {}
    };
  },
  mixins: [alerts]
};
</script>
