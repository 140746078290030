import { getTranslation } from "spiral";

export class AlertFilters {
  optimizedFilterMap = new WeakMap();

  constructor(study, userid, sites) {
    this.study = study;
    this.userid = userid;
    this.sites = sites;
    this._queryFilters = this.study.mainWorkflow.processProperties.query.reduce(
      (a, s) => ({
        ...a,
        creator: {
          ...a.creator,
          [`query:createdBy${getTranslation(s)}`]: this.queryCreatedBy(s)
        }
      }),
      {
        query: this.query(), // eslint-disable-line prettier/prettier
        creator: {
          "query:createdByMe": this.createdByMe()
        },
        status: {
          "query:open": this.queryOpen(),
          "query:closed": this.queryClosed(),
          "query:canceled": this.queryCanceled()
        },
        checkedBy: this.study.mainWorkflow.processProperties.checking.reduce(
          (a, s) => ({
            ...a,
            [`query:notCheckedBy${s}`]: this.queryNotCheckedBy(s)
          }),
          {}
        )
      }
    );
    this._ruleFilters = {
      dcf: this.dcf(),
      messages: {
        "dcf:required": this.requiredMessage(),
        "dcf:fixedLength": this.fixedLengthMessage(),
        "dcf:inRange": this.inRangeMessage(),
        "dcf:maxLength": this.maxLengthMessage()
      }
    };
    this._siteFilters = sites
      ?.map(s => s.siteCode)
      .reduce(
        (a, s) => ({
          ...a,
          [`site:${s}`]: this.isSite(s)
        }),
        {}
      );
    this._checkingFilters = this.study.mainWorkflow.processProperties.checking.reduce(
      (a, s) => ({
        ...a,
        checkedBy: {
          ...a.checkedBy,
          [`checking:notCheckedBy${s}`]: this.notCheckedBy(s)
        }
      }),
      {
        checking: this.checking(),
        checkedBy: {}
      }
    );
    this._filters = {
      ...this._queryFilters,
      ...this._ruleFilters,
      ...this._checkingFilters,
      ...this._siteFilters
    };
  }

  get queryCreatorFilters() {
    return Object.keys(this._queryFilters?.creator);
  }

  get queryStatusFilters() {
    return Object.keys(this._queryFilters?.status);
  }

  get queryCheckingFilters() {
    return Object.keys(this._queryFilters?.checkedBy);
  }

  get rulesFilters() {
    return Object.keys(this._ruleFilters);
  }

  get checkingByFilters() {
    return Object.keys(this._checkingFilters?.checkedBy);
  }

  get ruleMessageFilters() {
    return Object.keys(this._ruleFilters?.messages);
  }

  get siteFilters() {
    return Object.keys(this._siteFilters);
  }

  getFilter(f) {
    if (this.queryCreatorFilters.includes(f))
      return this._queryFilters.creator[f];
    if (this.queryStatusFilters.includes(f))
      return this._queryFilters.status[f];
    if (this.queryCheckingFilters.includes(f))
      return this._queryFilters.checkedBy[f];
    if (this.checkingByFilters.includes(f))
      return this._checkingFilters.checkedBy[f];
    if (this.ruleMessageFilters.includes(f))
      return this._ruleFilters.messages[f];
    return this._filters[f];
  }

  and(...functions) {
    return (alert, allAlerts) => {
      for (const f of functions) {
        const func = typeof f == "string" ? this.getFilter(f) : f;
        if (!func(alert, allAlerts)) return false;
      }
      return true;
    };
  }

  or(...functions) {
    return (alert, allAlerts) => {
      for (const f of functions) {
        const func = typeof f == "string" ? this.getFilter(f) : f;
        if (func(alert, allAlerts)) return true;
      }
      return false;
    };
  }

  applyTypeFilters(...filters) {
    const allFilters = [];

    const queryFilters = this.applyQueryFilters(...filters);
    if (queryFilters.length > 0) allFilters.push(this.and(...queryFilters));

    const checkingFilters = this.applyCheckingFilters(...filters);
    if (checkingFilters.length > 0)
      allFilters.push(this.and(...checkingFilters));

    const rulesFilters = this.applyRuleFilters(...filters);
    if (rulesFilters.length > 0) allFilters.push(this.and(...rulesFilters));

    if (allFilters.length > 0) return this.or(...allFilters);
    return undefined;
  }

  apply(...filters) {
    return (alert, allAlerts) => {
      const siteFilters = this.applySiteFilters(...filters);
      const typeFilters = this.applyTypeFilters(...filters);
      if (siteFilters && typeFilters)
        return this.and(...[siteFilters, typeFilters])(alert, allAlerts);
      else if (siteFilters && !typeFilters)
        return siteFilters(alert, allAlerts);
      else if (!siteFilters && typeFilters)
        return typeFilters(alert, allAlerts);
      else return true;
    };
  }

  applySiteFilters(...filters) {
    const siteFilters = filters.filter(f => this.siteFilters.includes(f));
    if (siteFilters.length > 0) return this.or(...siteFilters);
    return undefined;
  }

  applyRuleFilters(...filters) {
    const ruleFilters = filters.filter(f => f == "dcf");
    const ruleMessageFilters = filters.filter(f =>
      this.ruleMessageFilters.includes(f)
    );
    if (ruleMessageFilters.length > 0)
      ruleFilters.push(this.or(...ruleMessageFilters));
    return ruleFilters;
  }

  applyQueryFilters(...filters) {
    const queryFilters = filters.filter(f => f == "query");
    const queryCreatorFilters = filters.filter(f =>
      this.queryCreatorFilters.includes(f)
    );
    if (queryCreatorFilters.length > 0)
      queryFilters.push(this.or(...queryCreatorFilters));
    const queryStatusFilters = filters.filter(f =>
      this.queryStatusFilters.includes(f)
    );
    if (queryStatusFilters.length > 0)
      queryFilters.push(this.or(...queryStatusFilters));
    const queryCheckingFilters = filters.filter(f =>
      this.queryCheckingFilters.includes(f)
    );
    if (queryCheckingFilters.length > 0)
      queryFilters.push(this.or(...queryCheckingFilters));
    return queryFilters;
  }

  applyCheckingFilters(...filters) {
    const checkingFilters = filters.filter(f => f == "checking");
    const checkingByFilters = filters.filter(f =>
      this.checkingByFilters?.includes(f)
    );
    if (checkingByFilters.length > 0)
      checkingFilters.push(this.or(...checkingByFilters));
    return checkingFilters;
  }

  query() {
    return alert => alert.type == "query" || alert.Type == "query";
  }

  checking() {
    return alert => alert.type == "checking" || alert.Type == "checking";
  }

  dcf() {
    return alert => alert.type == "rule" || alert.Type == "rule";
  }

  queryCreatedBy(role) {
    return alert => alert.tags.tag == role;
  }

  queryOpen() {
    return alert =>
      this._filters.query(alert) &&
      (alert.tags.state == "open" ||
        (alert.tags.state != "closed" && alert.tags.state != "canceled"));
  }

  queryClosed() {
    return alert => this._filters.query(alert) && alert.tags.state == "closed";
  }

  queryCanceled() {
    return alert =>
      this._filters.query(alert) && alert.tags.state == "canceled";
  }

  queryNotCheckedBy(role) {
    return (alert, allAlerts) =>
      this.getOptimizedFilters(allAlerts).queryNotCheckedBy(alert, role);
  }

  getOptimizedFilters(allAlerts) {
    const optimized = this.optimizedFilterMap.get(allAlerts);
    if (typeof optimized == "undefined") {
      const queryNotCheckedBy = this.buildQueryNotCheckedBy(allAlerts);
      const optimized = {
        queryNotCheckedBy
      };
      this.optimizedFilterMap.set(allAlerts, optimized);
      return optimized;
    }
    return optimized;
  }

  buildQueryNotCheckedBy(allAlerts) {
    const notCheckedSet = new Set(
      allAlerts
        .filter(a => this._filters.checking(a))
        .reduce((r, a) => {
          const keys = a.tags.variableNames.map(name =>
            this.checkingKey(a, name, a.tags.step)
          );
          r.push(...keys);
          return r;
        }, [])
    );
    return (alert, role) => {
      const key = this.checkingKey(alert, alert.item.variableName, role);
      return this._filters.query(alert) && notCheckedSet.has(key);
    };
  }

  checkingKey(alert, variableName, role) {
    const code = getTranslation(
      alert.interview.type ?? alert.interview.pageSet.type,
      "__code__"
    );
    const date =
      typeof alert.interview.date == "string"
        ? alert.interview.date
        : alert.interview.date?.toISOString();
    return `${alert.patientCode}:${code}:${date}:${variableName}:${role}`;
  }

  notCheckedBy(role) {
    return alert => this._filters.checking(alert) && alert.tags.step == role;
  }

  requiredMessage() {
    return alert =>
      this._filters.dcf(alert) && alert.message == "value is required";
  }

  fixedLengthMessage() {
    return alert =>
      this._filters.dcf(alert) &&
      alert.message.startsWith("text length must be");
  }

  maxLengthMessage() {
    return alert =>
      this._filters.dcf(alert) &&
      alert.message.startsWith("Text must be less than");
  }

  inRangeMessage() {
    return alert =>
      this._filters.dcf(alert) &&
      alert.message.startsWith("value must be in range");
  }

  createdByMe() {
    return alert =>
      this._filters.query(alert) && alert.tags.creator == this.userid;
  }

  isSite(siteCode) {
    return alert => alert.siteCode == siteCode;
  }
}

export default {
  computed: {
    profiles() {
      return {
        investigator: [["query", "query:open"], ["dcf"]],
        cra: [
          [
            "query",
            "query:closed",
            "query:createdByMe",
            "query:notCheckedByCRA"
          ],
          ["checking", "checking:notCheckedByCRA", "query:createdByCRA"]
        ],
        datamanager: [
          [
            "query",
            "query:closed",
            "query:createdByMe",
            "query:notCheckedByDM"
          ],
          ["dcf", "checking", "checking:notCheckedByDM", "query:createdByDM"]
        ]
      };
    },
    alertFilters() {
      return new AlertFilters(
        this.currentStudy,
        this.currentUser.userid,
        this.currentUser.sites
      );
    }
  }
};
