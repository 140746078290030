<template>
  <div id="alert-table">
    <v-text-field
      outlined
      v-model="search"
      :label="$t('search')"
      id="search-alert"
      clearable
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="rows"
      :loading="loading"
      :loading-text="$t('events.loading')"
      :search="search"
    >
      <template v-slot:[`item.Message`]="{ item }">
        <v-chip :color="colors[item.Type]" :to="link(item)" href="#" outlined>
          {{ item.Message }}
        </v-chip>
      </template>
      <template v-slot:[`item.Type`]="{ item }">
        {{ itemType(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatCode } from "spiral";
import alerts from "../../mixin/alerts";
export default {
  props: ["filter"],
  data() {
    return {
      allAlerts: [],
      loading: false,
      search: undefined
    };
  },
  computed: {
    colors() {
      return {
        [this.$t("query")]: "info",
        [this.$t("rule")]: "danger",
        [this.$t("checking")]: "warning"
      };
    },
    filteredAlerts() {
      const apply = this.alertFilters.apply(...this.filter);
      return this.allAlerts.filter(a => apply(a, this.allAlerts));
    },
    rows() {
      return this.filteredAlerts.map(a => ({
        [this.$t("site")]: a.siteCode,
        [this.$t("patient")]: formatCode(
          { patientCode: a.patientCode },
          this.currentStudy.options
        ),
        [this.$t("visit")]: this.getLabel(
          "mlstring",
          {},
          a.interview.type,
          this.$i18n
        ),
        [this.$t("date")]: this.getLabel(
          "date",
          {},
          a.interview.date,
          this.$i18n
        ),
        [this.$t("page")]: this.getLabel(
          "mlstring",
          {},
          a.page.name,
          this.$i18n
        ),
        [this.$t("wording")]: this.getLabel(
          "mlstring",
          {},
          a.item?.wording,
          this.$i18n
        ),
        [this.$t("message")]: a.message,
        [this.$t("type")]: this.$t(a.type),
        tags: a.tags
      }));
    },
    headers() {
      return [
        { text: this.$t("site"), value: this.$t("site") },
        { text: this.$t("patient"), value: this.$t("patient") },
        { text: this.$t("visit"), value: this.$t("visit") },
        { text: this.$t("date"), value: this.$t("date") },
        { text: this.$t("page"), value: this.$t("page") },
        { text: this.$t("wording"), value: this.$t("wording") },
        { text: this.$t("message"), value: this.$t("message") },
        { text: this.$t("type"), value: this.$t("type") }
      ];
    },
    messages() {
      const filteredAlertsByType = this.filter?.alertType
        ? this.allAlerts.filter(a => this.filter?.alertType == a.type)
        : this.allAlerts;
      return [...new Set(filteredAlertsByType.map(a => a.message))].sort();
    }
  },
  methods: {
    link(item) {
      const index = this.rows.indexOf(item);
      const alert = this.filteredAlerts[index];
      const route = {
        name: "Patient CRF",
        params: { patientCode: alert.patientCode },
        query: {
          visit: alert.interview.index,
          page: alert.page.index
        },
        hash: alert.item ? `#${alert.item.variableName}` : ""
      };
      return route;
    },
    filterType(a) {
      return a.type == this.filter?.alertType;
    },
    filterSubType(a) {
      switch (this.filter?.alertType) {
        case "checking":
          return a.tags?.step == this.filter?.subType;
        case "query":
          return a.tags?.closed == this.filter?.subType;
      }
    },
    sendMessages(messages) {
      this.$emit("input", { messages });
    },
    itemType(item) {
      switch (item.Type) {
        case "checking":
          return `${item.Type} - ${item.tags.step}`;
        case "query":
          return `${item.Type} - ${this.$t(item.tags.state)}`;
        default:
          return this.$t(item.Type);
      }
    }
  },
  mixins: [alerts],
  watch: {
    messages(value) {
      this.$emit("input", { messages: value });
    }
  },
  async mounted() {
    this.loading = true;
    const site =
      this.currentUser.sites.length == 1
        ? this.currentUser.sites[0]
        : undefined;
    const userSiteCodes = this.currentUser.sites.map(s => s.siteCode);
    const patients = await this.drivers.summaryDriver
      .getPatientSummaries(this.currentStudy, site, [
        "patientCode",
        "siteCode",
        "alerts"
      ])
      .then(res => res.filter(p => userSiteCodes.includes(p.siteCode)));
    this.allAlerts = patients.reduce(
      (r, p) => [
        ...r,
        ...p.alerts.map(a => ({
          ...a,
          type: a.type,
          siteCode: p.siteCode,
          patientCode: p.patientCode
        }))
      ],
      []
    );
    this.sendMessages(this.messages);
    this.loading = false;
  },
  i18n: {
    messages: {
      en: {
        message: "Message",
        wording: "Wording",
        site: "Site",
        patient: "Patient",
        visit: "Visit",
        page: "Page",
        date: "Date",
        type: "Type",
        rule: "DCF",
        checking: "checking",
        closed: "Closed",
        open: "Open",
        canceled: "Canceled",
        search: "Search"
      },
      fr: {
        message: "Message",
        wording: "Intitulé",
        variable: "Variable",
        site: "Centre",
        patient: "Patient",
        visit: "Visite",
        page: "Page",
        date: "Date",
        type: "Type",
        rule: "DCF",
        checking: "checking",
        closed: "Fermée",
        open: "Ouverte",
        canceled: "Annulée",
        search: "Recherche"
      }
    }
  }
};
</script>
